import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';
import styled from 'styled-components';
import SettingsValueInput from './SettingsValueInput';
import { Button, Heading, Text, Tag, Flex, ToggleSwitch, Radio, RadioGroup, TextInput, Tooltip } from '@postman/aether';
import { TooltipBody } from '../base/Tooltips';
import FeatureFlagsStore from '@postman-app-monolith/renderer/js/stores/FeatureFlagsStore';
import { resolveStoreInstance } from '@postman-app-monolith/renderer/js/stores/StoreManager';
import ConfigurationStore from '@postman-app-monolith/renderer/js/stores/ConfigurationStore';
import { observer } from 'mobx-react';
import AnalyticsService from '../../modules/services/AnalyticsService';
import Link from '../../../appsdk/components/link/Link';
import AutosaveStore from '../../../runtime-repl/_common/AutosaveStore';
import { StyledRadioGroupContainer } from './styled-components';
import LanguageSelector from './LanguageSelector';
import { openExternalLink } from '@postman-app-monolith/renderer/js/external-navigation/ExternalNavigationService';
import DeepLinkingSwitchFlag from '@postman-app-monolith/renderer/js/services/DeepLinkingToastFlag';
import HTTPProtocolVersionSelector from '../../../runtime-repl/request-http/HTTPProtocolVersionSelector';

import {
  SEND_POSTMAN_TOKEN_HEADER,
  SEND_NO_CACHE_HEADER,
  XHR_TIMEOUT,
  MAX_RESPONSE_SIZE,
  AUTO_FOLLOW_REDIRECTS,
  RESPONSE_FONT_SIZE,
  RETAIN_HEADERS,
  LANGUAGE_DETECTION,
  SEND_ANONYMOUS_DATA,
  SSL_CERT_VERIFY,
  REQUESTER_TAB_LAYOUT,
  VARIABLE_AUTOCOMPLETE,
  CATEGORY_EDITOR,
  OPEN_IN_NEW,
  SKIP_CONFIRMATION_BEFORE_CLOSE,
  SHOW_ICONS,
  WORKING_DIR,
  INSECURE_FILEREAD,
  DISABLE_REQUEST_VALIDATION,
  REQUEST_EDITOR_LAYOUT_NAME,
  EDITOR_FONT_FAMILY,
  EDITOR_INDENT_COUNT,
  EDITOR_INDENT_TYPE,
  EDITOR_AUTOCLOSE_BRACKETS,
  EDITOR_AUTOCLOSE_QUOTES,
  USE_BROWSER_TABS,
  SHOW_REQUEST_SECURITY_WARNINGS_COUNT,
  AUTOSAVE,
  HTTP_VERSION
} from '../../constants/SettingsGeneralConstants';
import { OPEN_LINKS_IN_DESKTOP } from '@postman-app-monolith/renderer/uxfoundation/deep-link/constants';

import { REQUESTER_TAB_LAYOUT_2_COLUMN } from '@@runtime-repl/request-http/RequesterTabLayoutConstants';
import { WORKING_DIR_DOCS } from '../../constants/AppUrlConstants';
import { DEFAULT_WORKING_DIR } from '@@runtime-repl/_common/FsConstants';
import { TYPES } from '@@runtime-repl/agent/AgentConstants';
import { DOCUMENTATION_EDITOR, DOCUMENTATION_SETTINGS } from '@postman-app-monolith/renderer/documentation/constants';
import SettingsConnectionMode from './SettingsConnectionMode';
import WorkbenchService from '../../../appsdk/workbench/WorkbenchService';

import { RequestSecurityOverviewService } from '@postman-app/request-security-overview';
import { IconStateWarningStrokeSmall } from '@postman/aether-icons';

const languageDetectionLabelMap = {
  'auto': 'Auto',
  'JSON': 'JSON'
};

const DeepLinkContainer = styled(Flex)`
  &.highlighted {
    animation-duration: 4s;
    animation-name: color-change;
    animation-iteration-count: 1;
  }

  @keyframes color-change {
    0% { background-color: unset; }
    50% { background-color: var(--background-color-warning); }
    100% { background-color: unset; }
  }
`;

const ToolTipWrapper = ({ shouldShowToolTip, children, tooltipContent }) => {
  if (shouldShowToolTip) {
    return (<Tooltip placement='bottom'
      openDelay={0}
      closeDelay={0}
      content={<TooltipBody>{tooltipContent}</TooltipBody>}
            >
      {children}
    </Tooltip>);
  }
  return children;
};

@observer
class SettingsGeneral extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isWorkingDirAccessible: true,
      isSecurityTabEnabled: true,
      isSecurityWarningsCountEnabled: true
    };

    this.handleFileSelect = this.handleFileSelect.bind(this);
  }

  componentDidMount () {
    this.setWorkingDirAccess(this.props.generalSettings.workingDir);
    RequestSecurityOverviewService.isFeatureEnabled().then((isSecurityTabEnabled) => {
      const isSecurityWarningsCountEnabled = RequestSecurityOverviewService.showSecurityWarningsCount();

      // show setting when `isSecurityTabEnabled` and `isVisible` both are true
      this.setState({
        isSecurityTabEnabled: isSecurityTabEnabled,
        isSecurityWarningsCountEnabled: isSecurityWarningsCountEnabled
      });
    });
  }

  /**
   *
   * @returns - returns the status of the show warnings count flag
   */
  isSecurityWarningsCountActive () {
    const userConfigValue = this.props.generalSettings[SHOW_REQUEST_SECURITY_WARNINGS_COUNT];

    if (typeof userConfigValue === 'boolean') {
      return userConfigValue;
    } else {
      return this.state.isSecurityWarningsCountEnabled;
    }
  }


  setWorkingDirAccess (directory) {
    // If no directory selected then set the default working-dir
    !directory && (directory = DEFAULT_WORKING_DIR);

    pm.runtime.pathAccessible(directory, true, (err) => {
      if (err) {
        pm.logger.warn('SettingsGeneral~setWorkingDirAccess - Directory not accessible', err);
        this.setState({ isWorkingDirAccessible: false });
      } else {
        this.setState({ isWorkingDirAccessible: true });
      }
    });
  }

  handleFieldChange (key, value) {
    if (key === 'SSLCertVerify') {
      let action = value ? 'set_ssl_cert_verification_true' : 'set_ssl_cert_verification_false';

      AnalyticsService.addEvent('app', action, 'setting_modal', 1);
    }

    if (key === 'HTTPVersion') {

      AnalyticsService.addEventV2({
        category: 'request',
        action: 'globalHttpVersionChange',
        label: value,
        value: 1
      });
    }

    if (key === DOCUMENTATION_SETTINGS.IS_POSTMAN_EDITOR_DEFAULT) {
      value = value === DOCUMENTATION_EDITOR.POSTMAN_EDITOR ? true : false;
    }

    // Added condition as the Request Validation setting is reflected in the UI in opposite
    // manner due to the change in https://postmanlabs.atlassian.net/browse/RUNTIME-3300
    if (key === DISABLE_REQUEST_VALIDATION) {
      value = !value;
    }

    this.props.onFieldChange && this.props.onFieldChange(key, value);
  }

  handleConfigChange (key, value) {
    if (!key) {
      return;
    }

    this.props.onConfigChange && this.props.onConfigChange(key, value);
  }

  handleFileSelect () {
    const { dialog } = require('@electron/remote');

    dialog.showOpenDialog({
      buttonLabel: this.props.t('settings:general.working_directory.location.dialog_button_label'),
      properties: ['openDirectory', 'createDirectory', 'treatPackageAsDirectory']
    }).then((result) => {
      if (_.isArray(result.filePaths) && result.filePaths.length === 1) {
        this.setWorkingDirAccess(result.filePaths[0]);
        this.handleFieldChange(WORKING_DIR, result.filePaths[0]);
      }
    });
  }

  getDisabledSettingStyles (baseClasses, cloudDisabled = false) {
    return classnames(baseClasses, { 'is-disabled': _.get(pm.runtime, 'agent.stat.type') === TYPES.XHR || cloudDisabled });
  }

  render () {
    let generalSettingsObj = this.props.generalSettings,
      generalSettingsLanguageDetection = languageDetectionLabelMap[generalSettingsObj.languageDetection],
      configsMap = resolveStoreInstance(ConfigurationStore).configs,
      workingDir = generalSettingsObj.workingDir || ((__SDK_PLATFORM__ === 'browser') ? '~/Postman/files' : DEFAULT_WORKING_DIR),
      REQUEST_VALIDATION_ENABLED = resolveStoreInstance(FeatureFlagsStore).isEnabled('requestValidation'),
      isXHRAgentSelected = _.get(pm.runtime, 'agent.stat.type') === TYPES.XHR,
      isCloudAgentSelected = _.get(pm.runtime, 'agent.stat.type') === TYPES.CLOUD;

      // i18n translation function
      const { t } = this.props;

      return (
      <Flex className='settings-general-wrapper' direction='column' gap='spacing-xl' >
        <Heading text={t('settings:general.label')} type='h2' />
        <Flex direction='column'>
          <Flex direction='column' padding={{ paddingBottom: 'spacing-m' }}>
            <Heading text={t('settings:general.request.label')} type='h4' />
          </Flex>
          <ToolTipWrapper shouldShowToolTip={isCloudAgentSelected || isXHRAgentSelected}
            tooltipContent={isCloudAgentSelected ? t('settings:general.cloud_agent_tooltip_not_available') :
              (isXHRAgentSelected ? t('settings:general.tooltip_not_available') : null)}
          >
            <Flex
              justifyContent='space-between'
              padding='spacing-l spacing-zero'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
              className={this.getDisabledSettingStyles('settings-general-list-item', isCloudAgentSelected)}
              gap='spacing-xl'
            >
              <Flex direction='column' shrink={1}>
              <Flex direction='row' gap='spacing-xs' alignItems='center'>
                <Text>{t('settings:general.request.protocol_version.label')}</Text>
                <Tag text={t('settings:new_tag')} color='purple' />
              </Flex>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.request.protocol_version.desc')}
              </Text>
              </Flex>
              <Flex basis='100px'>
                <HTTPProtocolVersionSelector
                  isDisabled={isXHRAgentSelected || isCloudAgentSelected}
                  value={isXHRAgentSelected ? 'auto' : (isCloudAgentSelected ? 'http1' : generalSettingsObj.HTTPVersion)}
                  onChange={this.handleFieldChange.bind(this, HTTP_VERSION)}
                />
              </Flex>
            </Flex>
          </ToolTipWrapper>
          <Flex
            gap='spacing-xl'
            padding='spacing-l spacing-zero'
            border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
          >
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:general.request.request_timeout.label')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.request.request_timeout.desc')}
              </Text>
            </Flex>
            <Flex basis='100px'>
              <SettingsValueInput
                value={generalSettingsObj.xhrTimeout}
                onValueChange={this.handleFieldChange.bind(this, XHR_TIMEOUT)}
                suffix={t('settings:general.request.request_timeout.unit')}
              />
            </Flex>
          </Flex>
          <ToolTipWrapper shouldShowToolTip={isXHRAgentSelected}
            tooltipContent={(isXHRAgentSelected ? t('settings:general.tooltip_not_available') : null)}
          >
            <Flex
              justifyContent='space-between'
              padding='spacing-l spacing-zero'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
              className={this.getDisabledSettingStyles('settings-general-list-item settings-general-list-item--max-res-size')}
              gap='spacing-xl'
            >
              <Flex direction='column' shrink={1}>
                <Text>{t('settings:general.request.max_response_size.label')}</Text>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:general.request.max_response_size.desc')}
                </Text>
              </Flex>
              <Flex basis='100px'>
                <SettingsValueInput
                  isDisabled={isXHRAgentSelected}
                  value={isXHRAgentSelected ? 0 : generalSettingsObj.maxResponseSize}
                  onValueChange={this.handleFieldChange.bind(this, MAX_RESPONSE_SIZE)}
                  suffix={t('settings:general.request.max_response_size.unit')}
                />
              </Flex>
            </Flex>
          </ToolTipWrapper>
          <ToolTipWrapper shouldShowToolTip={isXHRAgentSelected}
            tooltipContent={(isXHRAgentSelected ? t('settings:general.tooltip_not_available') : null)}
          >
            <Flex
              justifyContent='space-between'
              padding='spacing-l spacing-zero'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
              className={this.getDisabledSettingStyles('settings-general-list-item settings-general-list-item--ssl-verification')}
            >
              <Text>{t('settings:general.request.ssl_certificate_verification')}</Text>
              <ToggleSwitch
                isDisabled={isXHRAgentSelected}
                isChecked={isXHRAgentSelected ? true : generalSettingsObj.SSLCertVerify}
                onChange={this.handleFieldChange.bind(this, SSL_CERT_VERIFY)}
              />
            </Flex>
          </ToolTipWrapper>
          {

          // Disable Request Validation Setting is now of opposite polarity (Request Validation now)
          // due to the change in https://postmanlabs.atlassian.net/browse/RUNTIME-3300
          REQUEST_VALIDATION_ENABLED &&
          <Flex
            gap='spacing-xxxl'
            justifyContent='space-between'
            padding='spacing-l spacing-zero'
            border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
            data-testid='settings-general-list-item--disable-request-validation'
          >
            <Flex direction='column' shrink={1} width='360px'>
              <Text>{t('settings:general.request.req_validation.label')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.request.req_validation.desc')}
              </Text>
            </Flex>
            <Flex>
              <ToggleSwitch
                isChecked={!generalSettingsObj.disableRequestValidation}
                onChange={this.handleFieldChange.bind(this, DISABLE_REQUEST_VALIDATION)}
                data-testid='settings-general-disable-request-validation-toggle-switch'
              />
            </Flex>
          </Flex>
          }
          {this.state.isSecurityTabEnabled ?
            <Flex
              justifyContent='space-between'
              padding='spacing-l spacing-zero'
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
              id={SHOW_REQUEST_SECURITY_WARNINGS_COUNT}
              gap='xxxl'
            >
              <Flex direction='column' shrink={1} width='360px'>
                <Flex direction='row' gap='spacing-xs' alignItems='center'>
                  <Text>{t('settings:general.request.security_warn_count.label')}</Text>
                  <Tag text={t('settings:new_tag')} color='purple' />
                </Flex>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:general.request.security_warn_count.desc')}
                </Text>
              </Flex>
              <Flex>
                <ToggleSwitch
                  isChecked={this.isSecurityWarningsCountActive()}
                  onChange={(value) => {
                    AnalyticsService.addEventV2({
                      category: 'security_overview',
                      action: value ? 'enable_security_warnings_count_setting' : 'disable_security_warnings_count_setting',
                      label: 'settings',
                      value: 1
                    });
                    this.handleFieldChange(SHOW_REQUEST_SECURITY_WARNINGS_COUNT, value);
                  }}
                />
              </Flex>
            </Flex> : null
          }
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.request.lang_detection')}</Text>
            <StyledRadioGroupContainer>
              <RadioGroup
                name='settings-language-detection-radio-group'
                value={generalSettingsObj.languageDetection}
                onChange={this.handleFieldChange.bind(this, LANGUAGE_DETECTION)}
              >
                <Radio value='auto' label={t('settings:general.request.lang_detect_auto')} id='auto' />
                <Radio value='json' label={t('settings:general.request.lang_detect_json')} id='json' />
              </RadioGroup>
            </StyledRadioGroupContainer>
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Flex direction='column' padding={{ paddingBottom: 'spacing-m' }}>
            <Heading text={t('settings:general.working_directory.label')} hasBottomSpacing type='h4' />
              <Text type='para' lineLengthType='narrow'>
                <Trans
                  t={t}
                  i18nKey='settings:general.working_directory.collaborate'
                >
                  Collaborate on files used in requests by sharing your working directory. Learn how to
                  <Link to={WORKING_DIR_DOCS} target='_blank'>
                    <Text type='link-default' isExternal>
                      setup your working directory
                    </Text>
                  </Link>
                </Trans>
              </Text>
          </Flex>

          <Flex direction='column' justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }} data-testid='settings-general-list-item-working-dir'>
            <Text>{t('settings:general.working_directory.location.label')}</Text>
            <Flex gap='spacing-m' padding={{ paddingTop: 'spacing-xs' }}>
              <Flex width='100%' shrink={1} title={workingDir}>
                <TextInput
                  type='text'
                  value={workingDir}
                  data-testid='settings-general-list-item--working-dir_file-input'
                  isDisabled
                />
              </Flex>
              <Button
                className='working-dir__file-chooser'
                type='outline'
                onClick={this.handleFileSelect}
                isDisabled={__SDK_PLATFORM__ === 'browser'}
                text={t('settings:general.working_directory.location.button_label')}
                data-testid='settings-general-list-item--working-dir_file-button'
                minWidth='70px'
              />
            </Flex>
            {
              !this.state.isWorkingDirAccessible &&
              <Flex gap='spacing-xs' alignItems='center' padding={{ paddingTop: 'spacing-xs' }}>
                <IconStateWarningStrokeSmall color='content-color-warning' />
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:general.working_directory.location.selected_dir_not_exist')}
                </Text>
              </Flex>
            }
            {
              (__SDK_PLATFORM__ === 'browser') &&
              <Flex gap='spacing-xs' alignItems='center' padding={{ paddingTop: 'spacing-xs' }}>
                <IconStateWarningStrokeSmall color='content-color-warning' />
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:general.working_directory.location.dir_cannot_be_configured')}
                </Text>
              </Flex>
            }
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' gap='spacing-xxxl' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:general.working_directory.location.allow_reading_files')}</Text>
              <Flex gap='spacing-xs'>
                <Flex height='16px' alignItems='center'>
                  <IconStateWarningStrokeSmall color='content-color-warning' />
                </Flex>
                <Flex shrink={1}>
                  <Text
                    color='content-color-secondary'
                    typographyStyle={{
                      fontSize: 'text-size-s',
                      lineHeight: 'line-height-s',
                    }}
                    lineLengthType='narrow'
                  >
                    {t('settings:general.working_directory.location.allow_reading_files_explanation')}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <ToggleSwitch
                isChecked={generalSettingsObj.insecureFileRead}
                onChange={this.handleFieldChange.bind(this, INSECURE_FILEREAD)}
                data-testid='settings-general-list-item--insecure-fileread'
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Flex padding={{ paddingBottom: 'spacing-m' }}>
            <Heading text={t('settings:general.headers.label')} type='h4' />
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.headers.send_no-cache_header')}</Text>
            <ToggleSwitch
              isChecked={generalSettingsObj.sendNoCacheHeader}
              onChange={this.handleFieldChange.bind(this, SEND_NO_CACHE_HEADER)}
            />
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.headers.send_postman_header')}</Text>
            <ToggleSwitch
              isChecked={generalSettingsObj.sendPostmanTokenHeader}
              onChange={this.handleFieldChange.bind(this, SEND_POSTMAN_TOKEN_HEADER)}
            />
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.headers.retain_headers')}</Text>
            <ToggleSwitch
              isChecked={generalSettingsObj.retainLinkHeaders}
              onChange={this.handleFieldChange.bind(this, RETAIN_HEADERS)}
            />
          </Flex>
          <ToolTipWrapper shouldShowToolTip={isXHRAgentSelected}
            tooltipContent={(isXHRAgentSelected ? t('settings:general.tooltip_not_available') : null)}
          >
            <Flex
              justifyContent='space-between'
              padding='spacing-l spacing-zero'
              className={this.getDisabledSettingStyles('settings-general-list-item')}
              border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
            >
              <Text>{t('settings:general.headers.auto_follow_redirects')}</Text>
              <ToggleSwitch
                isDisabled={isXHRAgentSelected}
                isChecked={isXHRAgentSelected ? true : generalSettingsObj.interceptorRedirect}
                onChange={this.handleFieldChange.bind(this, AUTO_FOLLOW_REDIRECTS)}
              />
            </Flex>
        </ToolTipWrapper>
        </Flex>
        <Flex direction='column'>
          <Flex direction='column' padding={{ paddingBottom: 'spacing-m' }}>
            <Heading text={t('settings:general.user_interface.label')} type='h4' />
          </Flex>
          {// 'use browser tabs' setting is only available for Postman web application.
              __SDK_PLATFORM__ === 'browser' && (
              <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
                <Flex direction='column'>
                  <Flex direction='row' gap='spacing-xs' alignItems='center'>
                    <Text>{t('settings:general.user_interface.remove_tabs')}</Text>
                    <Tag text={t('settings:new_tag')} color='purple' />
                  </Flex>
                  <Text
                    color='content-color-secondary'
                    typographyStyle={{
                      fontSize: 'text-size-s',
                      lineHeight: 'line-height-s',
                    }}
                  >
                    {t('settings:general.user_interface.remove_tabs_explanation')}
                  </Text>
                </Flex>
                <ToggleSwitch
                  isChecked={configsMap.get(`${CATEGORY_EDITOR}.${USE_BROWSER_TABS}`)}
                  onChange={this.handleFieldChange.bind(this, `${CATEGORY_EDITOR}.${USE_BROWSER_TABS}`)}
                />
              </Flex>
            )
          }
          { // When 'use browser tabs' setting is enabled, opening anything in a new tab is an explicit action by the user.
            // They can use `cmd/ctrl + shift + click to open sidebar items in new browser tab.
            !WorkbenchService.isUseBrowserTabsActive() && (
              <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
                <Text>{t('settings:general.user_interface.open_sidebar_new_tab')}</Text>
                <ToggleSwitch
                  isChecked={configsMap.get(`${CATEGORY_EDITOR}.${OPEN_IN_NEW}`)}
                  onChange={this.handleFieldChange.bind(this, `${CATEGORY_EDITOR}.${OPEN_IN_NEW}`)}
                />
              </Flex>
            )}
          { // When 'use browser tabs' setting is enabled, we always show a browser prompt for unsaved changes,
            // Always ask when closing setting becomes redundant.
            !WorkbenchService.isUseBrowserTabsActive() && (
              <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
                <Text>{t('settings:general.user_interface.ask_closing_tabs')}</Text>
                <ToggleSwitch
                  isChecked={!configsMap.get(`${CATEGORY_EDITOR}.${SKIP_CONFIRMATION_BEFORE_CLOSE}`)}
                  onChange={this.handleFieldChange.bind(
                    this,
                    `${CATEGORY_EDITOR}.${SKIP_CONFIRMATION_BEFORE_CLOSE}`
                  )}
                />
              </Flex>
            )
          }
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.user_interface.two-pane_view')}</Text>
            <ToggleSwitch
              isChecked={configsMap.get(`${CATEGORY_EDITOR}.${REQUEST_EDITOR_LAYOUT_NAME}`) === REQUESTER_TAB_LAYOUT_2_COLUMN}
              onChange={this.handleFieldChange.bind(this, REQUESTER_TAB_LAYOUT)}
            />
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.user_interface.show_icons_tab_names')}</Text>
            <ToggleSwitch
              isChecked={configsMap.get(`${CATEGORY_EDITOR}.${SHOW_ICONS}`)}
              onChange={this.handleFieldChange.bind(this, `${CATEGORY_EDITOR}.${SHOW_ICONS}`)}
            />
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.user_interface.var_autocomplete')}</Text>
            <ToggleSwitch
              isChecked={generalSettingsObj.variableAutocomplete}
              onChange={this.handleFieldChange.bind(this, VARIABLE_AUTOCOMPLETE)}
            />
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' gap='spacing-s' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.user_interface.default_doc_editor')}</Text>
            <StyledRadioGroupContainer>
              <RadioGroup
                name='settings-general-list-item-radio-group'
                value={generalSettingsObj.isPostmanEditorDefault ? DOCUMENTATION_EDITOR.POSTMAN_EDITOR : DOCUMENTATION_EDITOR.MARKDOWN_EDITOR}
                onChange={this.handleFieldChange.bind(this, DOCUMENTATION_SETTINGS.IS_POSTMAN_EDITOR_DEFAULT)}
              >
                <Radio label={t('settings:general.user_interface.postman_editor')} value={DOCUMENTATION_EDITOR.POSTMAN_EDITOR} id={DOCUMENTATION_EDITOR.POSTMAN_EDITOR} />
                <Radio label={t('settings:general.user_interface.markdown_editor')} value={DOCUMENTATION_EDITOR.MARKDOWN_EDITOR} id={DOCUMENTATION_EDITOR.MARKDOWN_EDITOR} />
              </RadioGroup>
            </StyledRadioGroupContainer>
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Flex justifyContent='space-between' padding={{ paddingBottom: 'spacing-m' }}>
            <Heading text={t('settings:general.editor_settings.label')} type='h4' />
            <Button
              className='reset-editor-settings'
              type='outline'
              size='small'
              tooltip={t('settings:general.editor_settings.reset_button_tooltip')}
              onClick={this.props.onResetEditorSettings}
              text={t('settings:general.editor_settings.reset_button_label')}
            />
          </Flex>
          <Flex gap='spacing-xxxl' justifyContent='space-between' alignItems='center' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.editor_settings.font_family')}</Text>
            <Flex shrink={1} wrap='wrap' basis='264px'>
              <SettingsValueInput
                value={generalSettingsObj.editorFontFamily}
                onValueChange={this.handleFieldChange.bind(this, EDITOR_FONT_FAMILY)}
              />
            </Flex>
          </Flex>
          <Flex justifyContent='space-between' gap='spacing-xl' alignItems='center' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.editor_settings.font_size')}</Text>
            <Flex basis='100px'>
              <SettingsValueInput
                type='number'
                minVal={0}
                value={generalSettingsObj.responseFontSize}
                onValueChange={this.handleFieldChange.bind(this, RESPONSE_FONT_SIZE)}
                suffix={t('settings:general.editor_settings.font_size_unit')}
              />
            </Flex>
          </Flex>
          <Flex justifyContent='space-between' gap='spacing-xl' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:general.editor_settings.indentation_count')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.editor_settings.indentation_count_explanation')}
              </Text>
            </Flex>
            <Flex basis='100px'>
              <SettingsValueInput
                type='number'
                minVal={0}
                maxVal={8}
                value={generalSettingsObj.editorIndentCount}
                onValueChange={this.handleFieldChange.bind(this, EDITOR_INDENT_COUNT)}
              />
            </Flex>
          </Flex>
          <Flex justifyContent='space-between' gap='spacing-xl' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Flex direction='column' shrink={1}>
              <Text>{t('settings:general.editor_settings.indentation_type')}</Text>
              <Text
                color='content-color-secondary'
                typographyStyle={{
                  fontSize: 'text-size-s',
                  lineHeight: 'line-height-s',
                }}
              >
                {t('settings:general.editor_settings.indentation_type_explanation')}
              </Text>
            </Flex>
            <StyledRadioGroupContainer>
              <RadioGroup
                name={this.state.uniqueName}
                value={generalSettingsObj.editorIndentType}
                onChange={this.handleFieldChange.bind(this, EDITOR_INDENT_TYPE)}
              >
                <Radio value='space' label={t('settings:general.editor_settings.indentation_type_space')} id='space' />
                <Radio value='tab' label={t('settings:general.editor_settings.indentation_type_tab')} id='tab' />
              </RadioGroup>
            </StyledRadioGroupContainer>
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <div className='settings-general-list-item-label'>
              <Text>{t('settings:general.editor_settings.auto_close_brackets')}</Text>
            </div>
            <div className='settings-general-list-item-value editor-auto-close-brackets'>
              <ToggleSwitch
                isChecked={generalSettingsObj.editorAutoCloseBrackets}
                onChange={this.handleFieldChange.bind(this, EDITOR_AUTOCLOSE_BRACKETS)}
              />
            </div>
          </Flex>
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <div className='settings-general-list-item-label'>
              <Text>{t('settings:general.editor_settings.auto_close_quotes')}</Text>
            </div>
            <div className='settings-general-list-item-value editor-auto-close-quotes'>
              <ToggleSwitch
                isChecked={generalSettingsObj.editorAutoCloseQuotes}
                onChange={this.handleFieldChange.bind(this, EDITOR_AUTOCLOSE_QUOTES)}
              />
            </div>
          </Flex>
        </Flex>
        <Flex direction='column'>
          <Flex padding={{ paddingBottom: 'spacing-m' }}>
            <Heading text={t('settings:general.application.label')} type='h4' />
          </Flex>
          { // Deep linking section redirecting to the browser settings page.
            DeepLinkingSwitchFlag.isEnabled &&
            __SDK_PLATFORM__ === 'desktop' &&
            (
              <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
                <Flex shrink={1} grow={1} direction='column'>
                  <Text>{t('settings:general.application.deep_linking.desktop.manage')}</Text>
                  <Text
                    color='content-color-secondary'
                    typographyStyle={{
                      fontSize: 'text-size-s',
                      lineHeight: 'line-height-s',
                    }}
                  >
                    {t('settings:general.application.deep_linking.desktop.manage_desc')}
                  </Text>
                </Flex>
                <Flex alignSelf='center' direction='column' padding={{ paddingLeft: 'spacing-l' }}>
                  <Button
                    type='outline'
                    className='settings-data-import-button settings-upload-button'
                    text={t('settings:general.application.deep_linking.desktop.manage_button')}
                    onClick={() => {
                      openExternalLink(
                        pm.config.get('__WP_EXPLORE_URL__'),
                        '_blank'
                      );
                    }}
                  />
                </Flex>
              </Flex>
            )
          }
          { // Enable deep linking setting is only available for Postman web application.
            DeepLinkingSwitchFlag.isEnabled &&
            __SDK_PLATFORM__ === 'browser' &&
            (
              <DeepLinkContainer
                justifyContent='space-between'
                gap='spacing-xxxl'
                padding='spacing-l spacing-zero'
                border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}
                className='settings__deep-link-switch'
              >
                <Flex direction='column' shrink={1}>
                  <Flex gap='spacing-xs' alignItems='center'>
                    <Text>
                      {t('settings:general.application.deep_linking.web.open_links_desktop')}
                    </Text>
                    <Tag text={t('settings:new_tag')} color='purple' />
                  </Flex>
                  <Text
                    color='content-color-secondary'
                    typographyStyle={{
                      fontSize: 'text-size-s',
                      lineHeight: 'line-height-s',
                    }}
                  >
                    {t('settings:general.application.deep_linking.web.open_links_desktop_desc')}
                  </Text>
                </Flex>
                <Flex minWidth='controls-size-default'>
                  <ToggleSwitch
                    isChecked={Boolean(generalSettingsObj[OPEN_LINKS_IN_DESKTOP])}
                    onChange={this.handleFieldChange.bind(this, OPEN_LINKS_IN_DESKTOP)}
                  />
                </Flex>
              </DeepLinkContainer>
            )
          }
          <LanguageSelector />
          {resolveStoreInstance(AutosaveStore).autosaveLDFlag ?
            <Flex justifyContent='space-between' gap='spacing-xl' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
              <Flex direction='column' shrink={1} width='360px'>
                <Flex direction='row' gap='spacing-xs' alignItems='center'>
                  <Text>{t('settings:general.application.autosave')}</Text>
                  <Tag text={t('settings:beta_tag')} color='blue' />
                </Flex>
                <Text
                  color='content-color-secondary'
                  typographyStyle={{
                    fontSize: 'text-size-s',
                    lineHeight: 'line-height-s',
                  }}
                >
                  {t('settings:general.application.autosave_explanation')}
                </Text>
              </Flex>
              <Flex>
                <ToggleSwitch
                  isChecked={generalSettingsObj.autosaveEnabled}
                  onChange={this.handleFieldChange.bind(this, AUTOSAVE)}
                />
              </Flex>
            </Flex> : null
          }
          <Flex justifyContent='space-between' padding='spacing-l spacing-zero' border={{ borderTop: 'border-width-default', borderColor: 'background-color-tertiary' }}>
            <Text>{t('settings:general.application.send_anonymous_data')}</Text>
            <ToggleSwitch
              isChecked={generalSettingsObj.googleAnalytics}
              onChange={this.handleFieldChange.bind(this, SEND_ANONYMOUS_DATA)}
            />
          </Flex>
          <SettingsConnectionMode />
        </Flex>
      </Flex>
    );
  }
}

export default withTranslation('settings')(SettingsGeneral);
